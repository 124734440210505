
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "ProductActivationHiddenFields",
  components: {},
})
export default class ProductActivationHiddenFields extends Vue {
  @Prop() paymentObjectWrapperId!: string;
}
