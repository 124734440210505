
import { Component, Inject, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { JPayPurchaseRequest, JPayPurchaseResponse, JPCInstance } from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import { ProductActivationData } from "@/types/localtypes";
import LoadingMask from "@/components/common/LoadingMask.vue";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";
import ProductActivationHiddenFields from "@/components/landingPages/ProductActivation/ProductActivationHiddenFields.vue";
declare var RATE_PLAN_ID_PRO_MONTHLY: any;

@Component({
  name: "ProductActivationViaCredit",
  components: { QuickPurchasePageTitle, JFCheckBox, LoadingMask, ProductActivationHiddenFields },
})
export default class ProductActivation extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Getter("productActivationData", { namespace: "productActivation" })
  productActivationData!: ProductActivationData;
  paymentObjectWrapperId = "payment-object-wrapper-activate-process";
  jpcLoaded = false;
  paymentScript = "";
  minHeight = 200;
  isLoading = true;
  currentRatePlanId = RATE_PLAN_ID_PRO_MONTHLY;
  forceDisableSubmitButton = false;

  async mounted() {
    // @ts-ignore
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        this.paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (this.paymentScript) {
          eval(this.paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.notifyError(this.$jfMessages.jpay_loading_failed);
      }
    }
    if (this.jpcLoaded) {
      this.loadJPCBuyForm();
    }
  }

  isMobile() {
    return this.$mq === "mobile";
  }

  get wrapperStyles() {
    const minWidth = this.isMobile() ? 200 : 500;
    return {
      minWidth: `${minWidth}px`,
    };
  }

  get jpcLogErrorPrefix() {
    return `jpc-callback-quick-purchase [${this.productActivationData.serverName}] : `;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
      zIndex: 10000, //to be on top of Zuora card iframe
    };
  }

  loadJPCBuyForm() {
    this.$log.info("loadJPCBuyForm");
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );
    setTimeout(() => {
      jpc.getCustomerCheckoutFormDirectly(this.getCustomerData());
    }, 2000);
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      optionalHeaders: {
        "x-product-activation-token": this.productActivationData.token,
        "x-product-activation-servername": this.productActivationData.serverName,
        "x-product-activation-product": this.productActivationData.product,
      },
      creditCardIframeWidth: 390,
      creditCardIframeHeight: 485,
      createFormForced: true,
    };
  }

  getCustomerData() {
    const email = `noreply_${this.productActivationData.serverName}@jfrog.com`;
    const result: JPayPurchaseRequest = {
      firstName: " ",
      lastName: " ",
      email: email,
      confirmEmail: email,
      phone: "",
      country: "United States",
      city: "dummy",
      state: "Alaska",
      address: "dummy",
      zip: "dummy",
      vatId: "",
      company: "dummy",
      billingFirstName: "dummy",
      billingLastName: "dummy",
      billingEmail: email,
      billingConfirmEmail: email,
      billingPhone: "123456789",
      billingAddress: "dummy",
      billingZip: "1",
      billingState: "",
      billingCountry: "United States",
      billingCity: "dummy",
      billingCompany: "dummy",
      ratePlanId: this.currentRatePlanId,
      additionalRatePlanIds: [],
      creditCardHolderName: "my name",
      creditCardCountry: "dummy",
      creditCardAddress1: "dummy",
      creditCardPostalCode: "dummy",
      paymentAccountId: "",
      paymentAccountNumber: "",
    };
    return result;
  }
  getJPCPrepopulate(): JPayPurchaseRequest {
    return this.getCustomerData();
  }

  getJPCMessages(): JPCInstance["messages"] {
    return {
      label_totalBeforeTax: "Total",
      label_tax: "Estimated Tax To Be Collected",
      label_amount: "Total",
    };
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      buyCustomerDetailsForm_hideOverlayFor3DS: () => {
        this.forceDisableSubmitButton = true;
        setTimeout(() => {
          this.setLoading(false);
        }, 3000);
      },
      buyCustomerDetailsForm_transformRatePlanId: (ratePlanId: string) => {
        return this.currentRatePlanId;
      },
      buyCustomerDetailsForm_submitCheckoutSuccess: (response: JPayPurchaseResponse) => {
        this.handlePurchaseSuccess(response);
        this.forceDisableSubmitButton = false;
      },

      buyCustomerDetailsForm_submitError: (status: number, errorMessage: string, exceptionClass: string) => {
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}${
          exceptionClass ? ` (exception : ${exceptionClass})` : ""
        }`;

        this.$log.error(jpcErrorMessage);
        this.setLoading(false);

        if (status === 401) {
          this.$jfUsers.handleUnauthorized();
          return;
        }

        if (exceptionClass && exceptionClass.includes("TaxValidationErrorException")) {
          // we don't show error notification because JPay will display UI errors into the form.
          return;
        }

        this.notifyError(this.$jfMessages.jpay_buy_form_submit_failed);
        this.forceDisableSubmitButton = false;
      },
      buyCustomerDetailsForm_submitCheckoutError: (message: string) => {
        this.$log.error(`${this.jpcLogErrorPrefix}${message}`);
        this.setLoading(false);

        this.notifyError(this.$jfMessages.extractPaymentsError(message));
      },
      buyCustomerDetailsForm_beforeSubmitCheckout: () => {
        this.setLoading(true);
        return true;
      },

      buyCustomerDetailsForm_beforeChangePage: () => {
        return true;
      },
      buyCustomerDetailsForm_afterChangePage: () => {
        setTimeout(() => {
          this.setLoading(false);
        }, 1500);
        return true;
      },
    };
  }

  async handlePurchaseSuccess(jpayPurchaseForm: JPayPurchaseResponse) {
    try {
      this.setLoading(true);
      const quickPurchaseResponse = await this.$jfSubscriptions.productActivation({
        purchaseForm: jpayPurchaseForm,
        serverName: this.productActivationData.serverName,
        token: this.productActivationData.token,
        product: this.productActivationData.product,
      });
      await this.$router.push({
        name: "/activate-product-thank-you",
        params: { platformUrl: quickPurchaseResponse.platformUrl },
      });
    } catch (e) {
      this.$log.error(`${this.jpcLogErrorPrefix} on success handler : ${e.message}`);
      this.setLoading(false);
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
    }
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
  }
  get paymentObjectWrapperClasses() {
    const classes = [];
    classes.push("w-100");
    return classes;
  }

  get buyFormWrapperStyle() {
    return {
      minHeight: this.minHeight + "px",
    };
  }

  handleNextStep() {
    this.$jfNotification.clearAll();
    const btnSubmit = document.getElementById(`${this.paymentObjectWrapperId}jpc-btn-submit`);
    btnSubmit && btnSubmit.click();
  }

  notifyError(errorMessage: string) {
    this.$log.error(errorMessage);
    this.globalBus.$emit("notifyError", errorMessage);
  }
}
